<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="费用名称">
              <a-input v-model="queryParam['%name']"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="费用类型">
              <a-select
                showSearch
                allowClear
                placeholder="请选择费用类型"
                v-model="queryParam['fee_type']"
              >
                <a-select-option v-for="op in feeTypeMaps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="所属部门">
              <a-select
                showSearch
                allowClear
                placeholder="请选择所属部门"
                v-model="queryParam['%departments']"
              >
                <a-select-option v-for="op in this.GLOBAL.departmentMaps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
      <a-button type="primary" icon="plus" @click="handleCreate">新建</a-button>
      <a-button type="danger" icon="delete" @click="handleDelete('all')" :disabled="!this.selectedRowKeys.length">删除</a-button>
    </div>

    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :columns="columns"
      :data="loadData"
      :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
      @dblclick="handleEdit">
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record, index">
        <template>
          <a @click="handleEdit(record, index)">编辑</a>
          <a-divider type="vertical" />
          <a @click="handleDelete('row', record)">删除</a>
        </template>
      </span>
    </s-table>

    <a-modal
      :visible="formVisible" :confirmLoading="confirmLoading"
      :title="modalFlag === 'create' ? '新建资料' : '编辑资料'"
      @cancel="formVisible = false"
      @ok="handleSubmit"
    >
      <a-form-model layout="vertical" :model="form"
                    :rules="rules" ref="form">
        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-model-item label="费用名称" prop="name">
              <a-input @input="handleNameChange" v-model="form.name"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="费用简码" prop="abbr">
              <a-input v-model="form.abbr"/>
            </a-form-model-item>
          </a-col>

        </a-row>
        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-model-item label="是否预付" prop="is_advance">
              <a-select
                showSearch
                allowClear
                placeholder="请选择是否预付"
                v-model="form.is_advance"
                style="width: 100%"
              >
                <a-select-option v-for="op in advanceOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="显示顺序" prop="order_num">
              <a-input v-model="form.order_num"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="所属部门" prop="departments">
              <a-select
                showSearch
                allowClear
                mode="multiple"
                placeholder="请选择所属部门"
                v-model="form.departments"
                style="width: 100%"
              >
                <a-select-option v-for="op in this.GLOBAL.departmentMaps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12" v-if="form.departments.indexOf(7)>-1">
            <a-form-model-item label="归属" prop="attribution_fee">
              <a-select
                showSearch
                allowClear
                placeholder="请选择归属"
                v-model="form.attribution_fee"
                style="width: 100%"
              >
                <a-select-option v-for="op in attributionFeeOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
  </a-card>
</template>
<script>
import { STable } from '@/components'
import { getFirstLetter } from '@/utils/util'
import {
  getCommonPage,
  createCommonInfo,
  updateCommonInfo,
  deleteCommonInfo,
  subjectOptions
} from '@/api/common'
export default {
  components: {
    STable
  },
  data() {
    return {
      selectedRowKeys: [],
      modalFlag: 'create',
      loadData: parameter => {
        return getCommonPage(this.moduleName, Object.assign(parameter, this.queryParam))
          .then(res => {
            return res
          })
      },
      form: {
        name: '',
        abbr: '',
        departments: [],
        is_advance: '',
        order_num: 1,
        attribution_fee: ''
      },
      formVisible: false,
      confirmLoading: false,
      feeTypeMaps: [
        { value: 0, name: '全部' }, { value: 1, name: '应收' }, { value: 2, name: '应付' }
      ],
      advanceOps: [
        { value: 1, name: '非预付' }, { value: 2, name: '预付' }
      ],
      attributionFeeOps: [
        { value: 0, name: '仓储包干收入' }, { value: 1, name: '报关报检收入' }, { value: 2, name: '代理运输收入' }
      ],
      queryParam: {},
      queryFields: [],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '费用名称',
          dataIndex: 'name'
        },
        {
          title: '费用简码',
          dataIndex: 'abbr'
        },
        {
          title: '所属部门',
          dataIndex: 'departments',
          customRender: text => {
            const names = []
            text = text || []
            text.forEach(i => {
              names.push(this.GLOBAL.departmentMaps[i].name)
            })
            return names.join(',')
          }
        },
        {
          title: '是否预付',
          dataIndex: 'is_advance',
          customRender: text => {
            let name = ''
            this.advanceOps.forEach(data => {
              if (data.value === text) {
                name = data.name
              }
            })
            return name
          }
        },
        {
          title: '显示顺序',
          dataIndex: 'order_num'
        },
        {
          title: '归属',
          dataIndex: 'attribution_fee',
          customRender: text => {
            let name = ''
            this.attributionFeeOps.forEach(data => {
              if (data.value === text) {
                name = data.name
              }
            })
            return name
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      rules: {
        name: [{ required: true, message: '请输入费用名称', trigger: 'blur' }]
      },
      modelTitle: '费用资料',
      modelLayout: {
        gutter: 20,
        span: 8
      },
      moduleName: 'fee',
      subjectOpts: []
    }
  },
  created() {
    subjectOptions().then(res => {
      this.subjectOpts = res
    })
  },
  methods: {
    handleCreate() {
      this.modalFlag = 'create'
      this.form = {
        name: '',
        abbr: '',
        departments: [],
        is_advance: '',
        order_num: 1,
        attribution_fee: ''
      }
      this.$nextTick(_ => {
        this.$refs.form.resetFields();
      })
      this.formVisible = true
    },
    handleNameChange() {
      this.form.abbr = getFirstLetter(this.form.name)
    },
    handleEdit(record) {
      this.modalFlag = 'update'
      this.formVisible = true
      this.form = record
    },
    handleDelete(flag, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          let _ids = []
          if (flag === 'all') {
            _ids = this.selectedRowKeys
          } else {
            _ids = [record.id]
          }
          deleteCommonInfo(this.moduleName, { ids: _ids }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.selectedRowKeys = [];
            this.$refs.table.refresh(true)
          })
        }
      })
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let func = createCommonInfo
          if (this.modalFlag === 'update') {
            func = updateCommonInfo
          }
          if (this.form.departments.indexOf(7) === -1) {
            this.form.attribution_fee = ''
          }
          func(this.moduleName, this.form).then(_ => {
            this.confirmLoading = false
            this.formVisible = false
            this.$notification['success']({
              message: '提示',
              description: this.modalFlag === 'update' ? '编辑成功' : '创建成功.'
            })
            if (this.modalFlag === 'create') {
              this.$refs.table.refresh(true)
            }
          }).catch(_ => {
            this.formVisible = false
            this.confirmLoading = false
          })
        } else {
          return false;
        }
      });
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    }
  }
}
</script>
